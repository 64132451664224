import axios from '@/plugins/axios';

class TicketService {
  getTickets(data) {
    this.data = data;
    return axios().get('tickets', { params: this.data }).then((response) => response.data);
  }

  getAllTickets(data) {
    this.data = data;
    return axios().get('admin/tickets', { params: this.data }).then((response) => response.data);
  }

  getTicket(id) {
    this.id = id;
    return axios().get(`tickets/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getAdminTicket(id) {
    this.id = id;
    return axios().get(`admin/tickets/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  sendTicket(data) {
    this.data = data;
    return axios().post('tickets', this.data);
  }

  updateTicket(data, id) {
    this.data = data;
    return axios().post(`tickets/${id}/messages`, this.data);
  }

  closeTicket(id) {
    this.id = id;
    return axios().put(`tickets/${id}/close`);
  }

  closeAdminTicket(id) {
    this.id = id;
    return axios().put(`admin/tickets/${id}/close`);
  }

  getTicketById(id) {
    this.data = id;
    return axios().get(`tickets/${id}`, { params: this.data }).then((response) => response.data);
  }
}

export default new TicketService();
