<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="`${ticket.complaint_title}`">
        <p class="p-support">
          <span><strong>Ticket:</strong> #{{ ticket.id }}</span>
          <span><strong>Aberto:</strong> {{ ticket.creation_date | formatDate24 }}</span>
          <span><strong>Cliente:</strong> {{ ticket.customer.name }}</span>
        </p>
        <span
          class="badge badge-success to-right"
          v-if="ticket.ra_status.name == 'Avaliado Resolvido'"
          >Resolvido e avaliado</span
        >
        <span
          class="badge badge-warning to-right"
          v-if="ticket.ra_status.name == 'Avaliado Não Resolvido'"
          >Avaliado Não Resolvido</span
        >
        <span
          class="badge badge-dark to-right"
          v-if="ticket.ra_status.name == 'Réplica da empresa'"
          >Réplica da empresa</span
        >
        <span
          class="badge badge-info to-right"
          v-if="ticket.ra_status.name == 'Respondido'"
          >Respondido</span
        >
        <span
          class="badge badge-danger to-right"
          v-else-if="ticket.ra_status.name == 'Não respondido'"
          >Não respondido</span
        >
        <router-link to="/ra/all"
          style="margin-top:-70px; position: absolute; right: 30px"
          class="btn btn-secondary"
        ><i class="fas fa-arrow-left color-white"></i> {{$t('generic-str.return')}}</router-link>
      </PageHeader>
      <div class="page-content container-fluid">
        <div v-if="ticket.status != 'archived'" class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <form action="#" class="form-horizontal">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="mini-tab-root tab-elevation1 tab-rounded"  style="margin: 10px; width:320px; float:right;">
                        <div class="mini-tab-content">
                          <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
                            <div class="mini-tab-flex">
                                <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                                  <span>
                                    Pública
                                  </span>
                                  <span class="MuiTouchRipple-root"></span>
                                </button>
                                <button style="width: 160px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, 160)">
                                  <span>
                                    Privada
                                  </span>
                                  <span class="MuiTouchRipple-root"></span>
                                </button>
                            </div>
                            <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}px;`"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group row">
                        <label class="control-label text-left col-3"
                          >{{$tc('generic-str.message', 1)}}</label
                        >
                        <div class="col-md-12">
                          <textarea
                            type="text"
                            v-model="form.message"
                            class="form-control"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-group row"  v-if="miniTab.tab == 1">
                        <label class="control-label text-left col-12"
                          >{{$t('generic-str.attach-file')}}</label
                        >
                        <div v-if="!importing" class="col-md-6">
                          <p style="color: #bdbebf;">* Obrigatório o anexo de um arquivo</p>
                          <input
                            ref="uploadedFile"
                            type="file"
                            hidden
                            @change="uploadFile"
                          />
                          <div class="input-group mb-3">
                            <div class="form-control">{{ form.file }}</div>
                            <div class="input-group-append">
                              <button
                                class="btn btn-primary"
                                type="button"
                                @click="openFileUpload"
                              >
                                Adicionar Arquivo
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else class="col-md-6">
                          <div class="row">
                            <div class="col-12">
                              <small>{{$t('sms.send-msg.tabs.archives.lbl-validating')}}</small>
                            </div>
                          </div>
                          <div class="progress">
                            <div
                              class="progress-bar progress-bar-striped progress-bar-animated"
                              role="progressbar"
                              :style="`width: ${uploadProgress}%;`"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {{ uploadProgress }}%
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row" v-for="(attachment, i) of form.attachments" :key="i">
                            <div class="col-sm-6">
                              <div class="card">
                                <div class="row p10">
                                  <div class="col-sm-10" style="padding-top:10px;">
                                    <h6>{{attachment.name}}</h6>
                                  </div>
                                  <div class="col-sm-2">
                                    <button class="btn btn-danger w100" type="button" @click="removeFile(i, attachment.id)">
                                      <i class="fas fa-times color-white"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="form-group row" v-if="miniTab.tab == 0">
                        <div class="col-sm-12 d-flex justify-content-between">
                          <button
                            type="button"
                            :class="{
                              'qt-loader qt-loader-mini qt-loader-right': isSending,
                            }"
                            :disabled="isSending"
                            class="btn btn-primary float-right"
                            @click="send()"
                          >
                            {{$t('generic-str.reply')}}
                          </button>
                          <button
                          v-if="ticket.status == 'open'"
                            type="button"
                            :class="{
                              'qt-loader qt-loader-mini qt-loader-right': isSending,
                            }"
                            :disabled="isSending"
                            class="btn btn-danger float-right"
                            @click="close()"
                          >
                            {{$t('generic-str.close')}}
                          </button>
                        </div>
                      </div>
                      <div class="form-group row" v-if="miniTab.tab == 1">
                        <div class="col-sm-12 d-flex justify-content-between">
                          <button
                            type="button"
                            :class="{
                              'qt-loader qt-loader-mini qt-loader-right': isSending,
                            }"
                            :disabled="isSending"
                            class="btn btn-primary float-right"
                            @click="sendPrivate()"
                          >
                            {{$t('generic-str.reply')}}
                          </button>
                          <button
                          v-if="ticket.status == 'open'"
                            type="button"
                            :class="{
                              'qt-loader qt-loader-mini qt-loader-right': isSending,
                            }"
                            :disabled="isSending"
                            class="btn btn-danger float-right"
                            @click="close()"
                          >
                            {{$t('generic-str.close')}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="form-row">
            <div class="col-md-4 m-top-10">
              <div class="form-group">
                <h4>{{$tc('generic-str.message', 2)}}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-for="message in messages" :key="message.id">
          <div class="col-12">
            <div
              class="card border-top border-left-info"
            >
              <div class="card-body">
                <h5 class="card-title">
                  {{ message.ticket_interaction_name }}
                </h5>
                <p class="card-text">{{ message.message }}</p>
                <p class="card-text">
                  <small class="text-muted">{{
                    message.creation_date | formatDate24
                  }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      class="static qt-block-ui"
      style="padding: 120px; margin-top: 15px"
    />
    <!-- <div
      v-else
      class="relative qt-block-ui"
      style="padding: 120px; margin-top: 15px"
    /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import TicketService from '@/services/ticket.service';
import PageHeader from '@/components/PageHeader.vue';
import BucketService from '@/services/bucket.service';
import RAService from '@/services/ra.service';
// import moment from 'moment';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  data() {
    return {
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      formData: null,
      fileImported: {},
      ticket: {},
      messages: [],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      beginDate: '',
      endDate: '',
      form: {
        message: '',
        created_at: '',
        attachments: [],
      },
      isSending: false,
      pages: 1,
      loading: true,
      exporting: false,
      importing: false,
      uploadProgress: 0,
      hasFile: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
    },
    removeFile(i, id) {
      BucketService.deleteFile(id).then(
        () => {},
        (error) => {},
      );
      this.form.attachments.splice(i, 1);
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const files = event.target.files;
      this.formData = new FormData();
      const file = event.target.files[0];
      this.formData.append('file', file);
      this.form.file = file.name;
      this.importing = false;
      this.hasFile = true;
    },
    fetch() {
      this.fetched = false;
      RAService.getTicketDetail(this.$route.params.id).then(
        (ticket) => {
          console.log('Ticket Detail');
          console.log(ticket);
          this.messages = ticket.data[0].interactions;
          this.ticket = ticket.data[0];
          this.fetched = true;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    info() {
      return this.$store.state.account;
    },
    close() {
      this.isSending = true;
      TicketService.closeTicket(this.ticket.id)
        .then(
          () => {
            this.fetch();
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('ticket-component.closed'),
              type: 'success',
            });
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    sendPrivate() {
      if (this.form.message.length === 0) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('ticket-component.category'),
          type: 'danger',
        });
        return;
      }

      this.isSending = true;
      console.log(this.form);
      this.formData.append('email', this.ticket.customer.email);
      this.formData.append('message', this.form.message);
      TicketService.replyTicketPrivate(this.formData, this.ticket.id)
        .then(
          (send) => {
            console.log(send);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-sent'),
              type: 'success',
            });
            this.form.message = '';
            this.form.attachments = [];
            this.isSending = false;
            this.fetch();
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
    send() {
      if (this.form.message.length === 0) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('ticket-component.category'),
          type: 'danger',
        });
        return;
      }

      this.isSending = true;
      console.log(this.form);
      TicketService.replyTicket(this.form, this.ticket.id)
        .then(
          (send) => {
            console.log(send);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-sent'),
              type: 'success',
            });
            this.form.message = '';
            this.form.attachments = [];
            this.isSending = false;
            this.fetch();
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
.p-support {
  color: #808080;
  span {
    margin-right: 24px;
  }
}
</style>
